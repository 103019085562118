@import "~@angular/material/theming";
@import "material-icons/iconfont/material-icons.css";
@include mat-core();

$sata-primary: mat-palette($mat-grey, 600, 800, 900);
$sata-accent: mat-palette($mat-red, 600, 700, 900);
$sata-warn: mat-palette($mat-red, A700);

$sata-theme: mat-light-theme($sata-primary, $sata-accent, $sata-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($sata-theme);
$primary: mat-color($sata-primary);
$accent: mat-color($sata-accent);
$warn: mat-color($sata-warn);

$custom-typography: mat-typography-config(
  $font-family: "Trebuchet MS"
);
@include mat-core($custom-typography);

body,
.mat-dialog-content {
  font-family: "Trebuchet MS";
}

.mat-dialog-content {
  padding-bottom: 10px !important;
}

.mat-dialog-actions {
  margin-bottom: -10px !important;
}

.mat-flat-button.mat-accent {
  margin-left: 8px;
}

.mat-tab-label {
  height: 58px !important;
}

.mat-tab-label.mat-tab-label-active {
  background-color: rgba(229, 56, 52, 0.73);
  color: white;
  opacity: 1;
}

.mat-ink-bar {
  background-color: #e53834 !important;
}

.mat-button-toggle-checked {
  background-color: rgba(229, 56, 52, 0.329) !important;
}

.rgba-text,
.hex-text,
.hsla-text,
.type-policy {
  display: none !important;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Arial, "Helvetica Neue", sans-serif;
}
